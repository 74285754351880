import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import IntegerField from '@/shared/fields/integer-field';
import BooleanField from '@/shared/fields/boolean-field';
import { ApplicationTypeField } from '@/modules/application-type/application-type-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`TAX_CALCULATOR.FIELDS.${name.toUpperCase()}`);

const fields = {
    application_type: ApplicationTypeField.relationToOne(
        'application_type',
        label('application_type'),
        { autocompleteRequired: true },
    ),
    uat: TerritorialAdministrativeUnitField.relationToOne(
        'uat',
        label('territorial_administrative_unit'),
        { autocompleteRequired: true },
    ),
    exemption: new BooleanField('exemption', label('exemption')),
    land_area: new IntegerField('land_area', label('land_area'), { required: true }),
};

export class TaxCalculatorModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
