import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class ApplicationTypeField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise(async (resolve) => {
                    ApiService.query('/application-types?limit=100').then(({ data }) => {
                        resolve(data);
                    });
                });
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options
        );
    }
}
